import Article from './Components/Article';
import Articles from './Components/Articles';
import Main from './Components/Main';
import Summary from './Components/Summary';
import Description from './Components/Description';
import Statistics from './Components/Statistics';
import Donate from './Components/Donate';
import Statistic from './Components/Statistic';
import React, { useState, useEffect } from "react";
import Loading from './Components/Loading';
import Map from './Components/Map';
import Metadata from './Components/Metadata';
import { flag, code, name, countries } from "country-emoji";
import Modal from 'react-modal';
import { EmojiProvider, Emoji } from 'react-apple-emojis';
import emojiData from 'react-apple-emojis/lib/data.json';

Modal.setAppElement('#root');

const Covid = () => {
	// Stats
	const [stats, setstats] = useState([]);
	const [statsLoading, setStatsLoading] = useState(false);
	const [statsError, setStatsError] = useState();
	
	const endpoint = 'Template:COVID-19_pandemic_data';
	const url = "https://www.wikitable2json.com/api/" + endpoint + "?cleanRef=true&table=0";

	const getStats = async () => {
		let resp;
		let stats = [];
		setStatsLoading(true);
		try {
			resp = await fetch(url);
			const json = await resp.json();
			stats = json
		} catch (err) {
			setStatsError(err);
		} finally {
			setStatsLoading(false);
		}
		setstats(stats[0]);
	};
	
	// News
	const [news, setnews] = useState([]);
	const [newsLoading, setNewsLoading] = useState(true);
	const [newsError, setNewsError] = useState();
	
	const [connected, setConnected] = useState(false);

	// Map
	const [zcontent, zsetContent] = useState("");

	// Function returns the countrys index
	const getCountryIndex = (country) => {
		return stats.findIndex(item => item[1] === name(code(country)));
	}

	// Vaccine data
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("United States");
	const [search, setSearch] = useState("");

	const getLocationFromIp = async () => {
		if(!city) {
			let resp;
			let stats = [];
			await fetch('https://ipapi.co/json/')
			try {
				resp = await fetch('https://ipapi.co/json');
				const json = await resp.json();
				stats = json
			} catch (err) {
				return null;
			} finally {
				return stats;
			}
		}
	};

	const handleSearch = (e) => {
		e.preventDefault();
		console.log(city);
		var url = "https://www.google.com/search?q=vaccination+centres+near+" + city + "+" + country;
		window.open(url, '_blank').focus();
	}

	useEffect(() => {
		// Stats
		getStats();
	
		// WS
		if(!connected) {
			const ws = new WebSocket(process.env.REACT_APP_WEBHOOK_URL + '/covid');
			ws.onmessage = function (event) {
				setConnected(true);
				setNewsLoading(true);
				const json = JSON.parse(event.data);
				console.log('🎁 Received ws message');
				try {
					if ((json.event = "data")) {
						setTimeout(function(){
							setnews(json);
							setNewsLoading(false);
						}, 1600);
					}
					} catch (err) {
						setNewsError(err);
						setConnected(false);
					}
			};
		}

		// Loc
		getLocationFromIp().then(data => {
			if(data) {
				setCity(data.city);
				setCountry(data.country_name);
				setSearch(data.city + ", " + data.country_name);
			}
		});

	}, []);

	// Modal
	const [modalIsOpen, setIsOpen] = useState(false);
	function openModal() {
		setIsOpen(true);
	  }
	
	  function closeModal() {
		setIsOpen(false);
	  }

	function getFlag(country) {
		return 'flag-' +
		country
		.replace('United States of America', 'united-states')
		.replace('Dominican Rep.', 'dominican-republic')
		.replace('Dem. Rep. Congo', 'congo-kinshasa')
		.replace('Congo', 'congo-kinshasa')
		.replace('Central African Rep.', 'central-african-republic')
		.replace('S. Sudan', 'central-african-republic')
		.replace('Somaliland', 'somalia')
		.replace('Myanmar', 'myanmar-burma')
		.replace('N. Cyprus', 'cyprus')
		.replace("Côte d'Ivoire", 'cote-divoire')
		.replace(/\s+/g, '-')
		.toLowerCase()
	}

	return (
		<>
			<Metadata
				title="COVID-19 Breaking News & Statistics"
				description="Interactive case map, breaking news and statistics on the ongoing worldwide coronavirus pandemic."
			/>
			<Summary title="COVID-19">
				<>
					<Donate title="Click here to make a donation" url="/donate" />
					<Description>

						<p>
							COVID-19 is caused by a new form of coronavirus known as SARS-CoV-2 (severe acute respiratory syndrome coronavirus 2). It was first reported in December 2019.
						</p>
					</Description>
					{ stats[0] && (
						<Statistics>
							<li className="mb-6 w-full sm:w-auto xl:w-full max-w-lg min-w-fit sm:mr-4">
								<Statistic
									key="Cases"
									title="World Case Total"
									figure={ stats[1][2].replace(/[a-z]/gi, '') }
								/>
							</li>
							<li className="mb-6 w-full sm:w-auto xl:w-full max-w-lg min-w-fit sm:mr-4">
								<Statistic
									key="Deaths"
									title="World Death Total"
									figure={ stats[1][3].replace(/[a-z]/gi, '') }
								/>
							</li>
						</Statistics>
					)}
					<div className="border-black/30 dark:border-white/30 text-black dark:text-white bg-black/10 dark:bg-white/10 border-[1px] p-6 rounded-2xl max-w-lg">
						<h2 className="font-bold mb-2">
							Find a vaccine center
						</h2>
						<p class="opacity-60 text-sm">
							Widespread vaccination is imperative to preventing the spread of COVID-19, search below to find your closest vaccination centre.
						</p>
						<form onSubmit={handleSearch} className="flex w-full mt-4">
							<input 
								className="w-3/5 text-black/50 dark:text-white/50 bg-black/10 dark:bg-white/20 rounded-tl-lg rounded-bl-lg p-2 outline-none"
								type="text"
								value={city}
								onChange={(e) => setCity(e.target.value)}
							/>
							<button onClick={ () => openModal(true) } type="button" className="text-2xl mr-4 rounded-tr-lg rounded-br-lg px-4 bg-black/10 dark:bg-white/20 flex items-center w-14">
								<i className="opacity-80 hover:opacity-100 not-italic">
									<EmojiProvider data={emojiData}>
										<Emoji 
											name={
												getFlag(country)
											}
										/>
									</EmojiProvider>
								</i>
							</button>
							<button type="submit">
								<svg className="h-4 opacity-80 hover:opacity-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.645 14.645">
									<path id="Icon_map-search" data-name="Icon map-search" d="M12.156,10.208a5.792,5.792,0,1,0-1.95,1.948l3.93,3.929,1.949-1.95Zm-4.938.586a3.573,3.573,0,1,1,3.575-3.571A3.579,3.579,0,0,1,7.218,10.794Z" transform="translate(-1.44 -1.44)" fill="#22acfe"/>
								</svg>
							</button>
						</form>
					</div>
				</>
			</Summary>
			<Main>
				<div className="text-black/20 dark:text-white/20">
					<Map setTooltipContent={zsetContent} graticule />
				</div>
				<div className="flex justify-center items-center mb-16">
					{ zcontent && (
						<>
							<span className="w-12 mr-8">
								<EmojiProvider data={emojiData}>
									<Emoji name={
										getFlag(zcontent)
									} />
								</EmojiProvider>
								{/* { flag(zcontent) } */}
							</span>
							<div className="w-fit flex gap-x-4">
								{ getCountryIndex(zcontent) !== -1 ? (
									<>
										<Statistic title="Total Cases" figure={stats[getCountryIndex(zcontent)][2].replace(/[a-z]/gi, '')} />
										<Statistic title="Total Deaths" figure={stats[getCountryIndex(zcontent)][3].replace(/[a-z]/gi, '')} />
									</>
								) : ( 'No Data ' )}
							</div>
						</>
					)}
				</div>
				{newsLoading &&
					<Loading />
				}
				<Articles>
					{newsError && <p>Error: {newsError.message}</p>}
					{(!newsLoading && news) && news.map(item => (
						<Article
							key={item.id}
							title={item['title']}
							url={item['url']}
							date={item['published date']}
							source={item['publisher']['title']}
						/>
					))}
				</Articles>
			</Main>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Vaccination Country"
				style={
					{
						overlay: {
							backgroundColor: 'rgba(0, 0, 0, 0.9)',
							zIndex: '10',
						},
						content: {
							backgroundColor: 'transparent',
							border: 'none',
						}
					}
				}
			>
				<div className="flex w-full h-full text-white">
					<div className="w-full flex flex-col justify-center items-center">
						<h2 className="text-2xl font-bold mb-4">
							Select country
						</h2>
						<div className="text-white/40 max-w-5xl w-full">
							<Map setTooltipContent={setCountry} disableEnter closeModal={setIsOpen} />
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}
 
export default Covid;