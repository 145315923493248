import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { useEffect, useState } from 'react';
import LetteredAvatar from 'react-lettered-avatar';

const Article = ({title, date, url, source}) => {
	const logos = {
		'CNN': 'cnn.png',
		'BBC.com': 'bbc.png',
		'The Guardian': 'guardian.jpg',
		'The New York Times': 'nyt.png',
		'The Washington Post': 'washington-post.jpg',
		'The Wall Street Journal': 'wsj.jpg',
		'Financial Times': 'financial-times.png',
		'Bloomberg': 'bloomberg.jpg',
		'Al Jazeera English': 'al-jazeera.png',
		'Reuters.com': 'reuters.jpg',
		'Reuters': 'reuters.jpg',
		'Atlantic Council': 'atlantic-council.png',
		"Barron's": 'barrons.png',
		'CNBC': 'cnbc.jpg',
		'Sky News': 'sky-news.jpg',
		'NDTV': 'ndtv.jpg',
		'Fox News': 'fox-news.png',
		'PBS NewsHour': 'pbs.jpg',
		'ABC News': 'abc-news.jpg',
		'The Associated Press - en Español': 'ap.png',
		'Associated Press': 'ap.png',
		'ESPN': 'espn.jpg',
		'Philadelphia Inquirer': 'philly-inquirer.jpg',
		'The New Yorker': 'new-yorker.jpg',
		'CBS News': 'cbs.png',
		'Forbes': 'forbes.jpg',
		'Vox.com': 'vox.jpg',
		'World Health Organization': 'who.jpg',
		'New York Post': 'new-york-post.png',
		'Fortune': 'fortune.png',
		'M-Live.com': 'mlive.jpg',
		'WSAZ': 'wsaz.png',
		"NPR": "npr.gif",
		"Axios": "axios.png",
		"NBC News": "nbc-news.png",
		"Politico Europe": "politico-europe.png",
		"Department of State": "department-of-state.png",
		"AL.com": "al.jpg",
		"WFLA": "wfla.jpg",
		"The Seattle Times": "the-seattle-times.jpg", 
		"The National Law Review": "the-national-law-review.png",
		"Fox 4 KDFW": "fox-4-kdfw.jpg",
		"Global News": "global-news.png",
		"TODAY": "today.png",
		"Just Security ": "just-security.jpg",
		"Greenwich time": "greenwich-time.jpg",
		"WKYT": "wkyt.jpg",
		"KY3": "ky3.jpg",
		"Fox 32 Chicago": "fox-32-chicago.png",
		"Military Times": "military-times.jpg",
		"Albany County": "albany-county.jpg",
		"Science Daily": "science-daily.jpg",
		"STAT": "stat.png",
		"JD Supra": "jd-supra.png",
		"8News": "8news.jpg",
		"KVEO-TV": "kveo-tv.jpg",
		"Northwestern-Now": "northwestern-now.jpg",
		"WRBL": "wrbl.jpg",
		"Cancer Network": "cancer-network.jpg",
		"City Of Fairfax, VA (.gov)": "city-of-fairfax-va-gov.png",
		"U.S. News & World Report": "u.s.-news-&-world-report.jpg",
		"NEWS10 ABC": "news10-abc.jpg",
		"MLive": "mlive.png",
		"KXAN.com": "kxan.com.png",
		"WHIO": "whio.png",
		"WAVY": "wavy.jpg", 
		"KARK": "kark.png",
		"WEAU": "weau.png",
		"HHS": "hhs.png",
		"NJ": "nj.jpg",
		"TIME": "time.png",
		"Deseret News": "deseret-news.jpg",
		"FDA": "fda.jpg",
		"WebMC": "webmc.png",
		"MaryLand": "maryland.png",
		"Gallup Poll": "gallup-poll.png",
		"CIDRAP": "cidrap.png",
		"The Pilot": "the-pilot.png",
		"Poynter": "poynter.jpg",
		"OCRegister": "ocregister.jpg",
		"Tulas World": "tulas-world.jpg",
		"PR Newswire": "pr-newswire.jpg",
		"CNET": "cnet.png",
		"Nature": "nature.jpg",
		"Nature.com": "nature.jpg",
		"The white House": "the-white-house.jpg",
		"Naperville": "naperville.gif",
		"90.5 WESA": "90.5-wesa.png",
		"The Economist": "the-economist.png",
		"GOV": "gov.png",
		"WWBT": "wwbt.png",
		"Seven Days": "seven-days.png",
		"Kasiser Health News": "kasiser-health-news.png",
		"MaretWatch": "maretwatch.jpg",
		"BioSpace": "biospace.png",
		"wpde": "wpde.png",
		"Truthout": "truthout.jpg",
		"WVLT": "wvlt.jpg",
		"WIRED": "wired.png",
		"KLAS": "klas.png",
		"CBS17": "cbs17.jpg",
		"ReliefWeb": "reliefweb.jpg",
		"VeloNews ": "velonews.png",
		"Duke Today": "duke-today.png",
		"NFL": "nfl.jpg",
		"Post-Crescent": "post-crescent.png",
		"Yale News": "yale-news.png",
		"Detroit Free Press": "detroit-free-press.png",
		"MassLive": "masslive.jpg",
		"Mass Device": "mass-device.png",
		"Medpage Today": "medpage-today.jpg",
		"Lexington Herald Leader": "lexington-herald-leader.jpg",
		"News9": "news9.jpg",
		"KPBS": "kpbs.png",
		"Bloomberg Law": "bloomberg-law.jpg",
		"Canton Repository": "canton-repository.jpg",
		"10TV": "10tv.png",
		"Bangor Daily News": "bangor-daily-news.png",
		"Live 5 News WCSC": "live-5-news-wcsc.png",
		"KCUR": "kcur.jpg",
		"Becker's Hospiral Review": "becker's-hospiral-review.png",
		"The Jerusalem Post": "the-jerusalem-post.png",
		"Roll Call": "roll-call.jpg",
		"The Motley Fool": "the-motley-fool.png",
		"PennLive": "pennlive.jpg",
		"FIDH": "fidh.jpg",
		"Fitch Ratings": "fitch-ratings.jpg",
		"Dell Medical School": "dell-medical-school.png",
		"AAMC": "aamc.jpg",
		"UKNow": "uknow.jpg",
		"Inside Higher Ed": "inside-higher-ed.jpg",
		"GeekWire": "geekwire.png",
		"WITN": "witn.jpg",
		"GenomeWeb": "genomeweb.jpg",
		"KSBY": "ksby.png",
		"The Patriot Ledger": "the-patriot-ledger.jpg",
		"UMass Lowell": "umass-lowell.jpg",
		"Press Herald": "press-herald.png",
		"CBC News": "cbc-news.jpg",
		"USA TODAY": "usa-today.png",
		"The Verge": "the-verge.png",
		"HBR.org Daily": "hbr-daily.png",
		"KTLA Los Angeles": "ktla-los-angeles.jpg",
		"Boston.com": "boston.gif",
		"The Daily Tribune": "the-daily-tribune.png",
		"SILive.com": "silive.jpg",
		"Healio": "healio.png",
		"The Week": "the-week.png",
		"WTOP": "wtop.png",
		"WTTW News": "wttw-news.png",
		"KGW.com": "kgw.png",
		"OregonLive": "oregonlive.png",
		"San Antonio Current": "san-antonio-current.jpg",
		"TAPinto.net": "tapinto.png",
		"Kitsap Sun": "kitsap-sun.png",
		"WWLP.com": "wwlp.png",
		"MPR News": "mpr-news.png",
		"SHINE": "shine.jpg",
		"WAVE 3": "wave-3.png",
		"News-Leader": "news-leader.png",
		"goskagit.com": "goskagit.png",
		"BethesdaMagazine.com": "bethesdamagazine.jpg",
		"S&P Global": "sp-global.png",
		"Xinhua": "xinhua.png",
		"Pharmaceutical Technology": "pharmaceutical-technology.png",
		"WSWS": "wsws.png",
		"KMOV4": "kmov4.jpg",
		"KRDO": "krdo.jpg",
		"TMJ4 News": "tmj4-news.jpg",
		"DIVDS": "divds.jpg",
		"Lowell Sun": "lowell-sun.jpg",
		"Marca English": "marca-english.jpg",
		"WIFR": "wifr.png",
		"Golf.com": "golf.png",
		"The Mercury News": "the-mercury-news.gif",
		"The Texas Tribune": "the-texas-tribune.png",
		"The Nation": "the-nation.jpg",
		"KING5.com": "king5.png",
		"The Gardner News": "the-gardner-news.png",
		"The Lancet": "the-lancet.png",
		"TechCrunch": "techcrunch.jpg",
		"syracuse.com": "syracuse.png",
		"Oswego County": "oswego-county.jpg",
		"Healthline": "healthline.jpg",
		"FDA.gov": "fda.jpg",
		"UN News": "un-news.jpg",
		"Virginia Department of Health": "virginia-department-of-health.jpg",
		"nyc.gov": "nyc.png",
		"nejm.org": "nejm.png",
		"Verywell Health": "verywell-health.png",
		"The White House": "the-white-house.png",
		"The University of Lowa": "the-university-of-lowa.png",
		"SPHP News": "sphp-news.jpg",
		"Bennington Banner": "bennington-banner.jpg",
		"9&10 News": "910-news.png",
		"GoErie.com": "goerie.jpg",
		"News-Medical.net": "news-medical.png",
		"The Arizona Republic": "the-arizona-republic.png",
		"Sports Illustrated": "sports-illustrated.png",
		"The Conversation": "the-conversation.png",
		"Boston Herald": "boston-herald.png",
		"Laker Country": "laker-country.png",
		"The Herald Bulletin": "the-herald-bulletin.png",
		"cleveland.com": "cleveland.png",
		"Project Syndicate": "Project-Syndicate.png",
		"Watauga Demoncrat": "watauga-demoncrat.jpg",
		"Yakima Herald-Republic": "yakima-herald-republic.png",
		"Salt Lake Tribune": "salt-lake-tribune.png",
		"Ophthalmology Times": "ophthalmology-times.jpg",
		"Nebraska Today": "nebraska-today.jpg",
		"Northwest herald": "northwest-herald.png",
		"WAFF": "waff.jpg",
		"Norml": "norml.png",
		"Us Weekly": "us-weekly.png",
		"Brainerd Dispatch": "brainerd-dispatch.jpg",
		"Yahoo Finance": "yahoo-finance.png",
		"Abccolumbia.com": "abccolumbia.png",
		"Kaiser Health News": "kaiser-health-news.png",
		"San Mateo Daily Journal": "san-mateo-daily-journal.png",
		"NJ.com": "nj.jpg",
		"American Medical Association": "american-medical-association.jpg",
		"Marketplace": "marketplace.jpg",
		"The Pharma Letter": "the-pharma-letter.png",
		"Law360": "law360.png",
		"WGLT": "wglt.png",
		"Wink News": "wink-news.jpg",
		"War on the Rocks": "war-on-the-rocks.png",
		"CTV News": "ctv-news.png",
		"Military.com": "military.jpg",
		"BBC": "bbc.png",
		"CBC.ca": "cbc.png",
		"DefenseNews.com": "defensenews.jpg",
		"The Associated Press": "the-associated-press.jpg",
		"The Guardian US": "the-guardian-us.jpg",
		"Republic World": "republic-world.png",
		"Microsoft": "microsoft.png",
		"HomeLand Security": "homeland-security.png",
		"KOMO News": "komo-news.png",
		"Courthouse News Service": "courthouse-news-service.jpg",
		"The Hill": "the-hill.jpg",
		"WMTW Portland": "wmtw-portland.jpg",
		"Kuna noticias y kuna radio": "Kuna-noticias-y-kuna-radio.jpg",
		"Oregon Public Broadcasting": "oregon-public-broadcasting.png",
		"Traverse City Record Eagle": "traverse-city-record-eagle.png",
		"MassLive.com": "masslive.jpg",
		"South China Morning Post": "south-china-morning-post.jpg",
		"NBC 29": "nbc-29.png",
		"Tampa Bay Times": "tampa-bay-times.jpg",
		"The Atlanta Journal Constitution": "the-atlanta-journal-constitution.jpg",
		"Euronews": "euronews.png",
		"KOMU 8": "komu-8.jpg",
		"MarketWatch": "marketwatch.jpg",
		"Source of the Spring": "source-of-the-spring.png",
		"Dove Medical Press": "dove-medical-press.png",
		"TCPalm": "tcpalm.png",
		"Democrat & Chronicle": "democrat-and-chronicle.jpg",
		"WCAX": "wcax.jpg",
		"Medical News Today": "medical-news-today.png",
		"Verdict Foodservice": "verdict-foodservice.png",
		"Fox Business": "fox-business.jpg",
		"The Federal": "the-federal.png",
		"Mondaq": "mondaq.jpg",
		"WHAV News": "whav-news.jpg",
		"Webster-Kirkwood Times, Inc": "webster-kirkwood-times-inc.jpg",
		"Daily Northwestern": "daily-northwestern.jpg",
		"Billboard": "billboard.png",
		"Daily Free Press": "daily-free-press.png",
		"Santa Maria Times": "santa-maria-times.png",
		"WHSV": "whsv.png",
		"Times of San Diego": "times-of-san-diego.jpg",
		"NBC Connecticut": "nbc-connecticut.jpg",
		"KTVL": "ktvl.jpg",
		"UI The Daily Iowan": "ui-the-daily-iowan.png",
		"Dartmouth News": "dartmouth-news.png",
		"WABI": "wabi.png",
		"SFist": "sfist.jpg",
		"Deadline": "deadline.png",
		"Milwaukee Journal Senticel": "milwaukee-journal-senticel.jpg",
		"Washington State Department of Health": "washington-state-department-of-health.jpg",
		"WebMD": "webmd.svg",
		"CMS": "cms.jpg",
		"LWW Jourenals": "lww-jourenals.jpg", 
		"WKRC TV Cincinnati": "wkrc-tv-cincinnati.jpg",
		"GloveNewswire": "glovenewswire.png",
		"nashville post": "nashville-post.jpg",
		"WCVB Boston": "wcvb-boston.png",
		"Steamboat Pilot & Today": "steamboat-pilot-&-today.png",
		"SciTechDaily": "scitechdaily.jpg",
		"VICE": "vice.png",
		"Mankato Free Press": "mankato-free-press.jpg",
		"Business Wire": "business-wire.png",
		"Raccoon Valley Radio": "raccoon-valley-radio.jpg",
		"Ohio Capital Journal": "ohio-capital-journal.png",
		"Hartford Courant": "hartford-courant.png",
		"CalMatters": "calmatters.png", 
		"Daily Mail": "daily-mail.jpg",
		"Metrowest Daily News": "metrowest-daily-news.png",
		"Med Device Online": "med-device-online.jpg",
		"Detroit News": "detroit-news.jpg",
		"Homeland Security": "us-homeland-security.png",
	}

	const [logo, setLogo] = useState();

	async function getLogoFromUrl(url) {
		const { hostname, protocol } = new URL(url);
		const string = protocol + '//' + hostname + '/favicon.ico';

		await fetch(string, {
			method: 'HEAD',
		}).then(
			function(response) {
				if (response.status == 200) {
					if(response.url.match(/\.(jpeg|jpg|gif|png|ico)$/)) {
						// Check image size
						const img = new Image();

						img.src = response.url;

						img.onload = function() {
							if(this.width > 16 && this.height > 16) {
								setLogo(response.url);
							}

							else {
								setLogo("err");
							}
						}

						img.onerror = function() {
							setLogo("err");
						}

					}
					else {
						setLogo("err");
					}
				}
				else {
					setLogo("err");
				}
			}
		);
	}

	function getLogo(source, url) {
		const path = '/assets/publishers/';
		const logo = logos[source];
		if (logo) {
			setLogo(path + logo);
		}
		else {
			getLogoFromUrl(url);
		}
	}

	useEffect(() => {
		console.log(
			"%c404 Errors Incoming" + 
			"%c\nDuring the process of displaying news articles, we perform a check to the news source to retrieve their logo. As a result, any sources that can not accomodate this request will throw an error in console (404, 503, 403... etc)",
			
			"background: red; font-size: 18px; color: white; padding: 4px; text-decoration: underline;",
			"background: red; font-size: 12px; color: white;"
		);
		getLogo(source, url);
	}, [source, url]);

	return (
		<a href={url} target="_blank" rel="noopener noreferrer">
			<div className="border-black/30 dark:border-white/30 bg-black/10 dark:bg-white/10 hover:dark:bg-white/20 hover:bg-black/20 transition-all duration-500 text-black dark:text-white border-[1px] px-6 py-4 rounded-2xl">
				<span class="opacity-50 text-sm">
					<Moment fromNow>{date}</Moment>
				</span>
				<div className="flex justify-between mb-3">
					<div class="w-auto">
						<p>{title}</p>
					</div>
					<div className="w-24 h-24 object-cover ml-4 flex-shrink-0 flex-grow-0 basis-24">
						{ logo !== "err" && (
							<img
								className="h-full w-full"
								src={logo} alt=""
							/>
						)}
						{ logo === "err" && (
							<LetteredAvatar
								name={source}
								radius={0}
								size={"100%"}
								backgroundColors={[
									'#2ecc71',
									'#3498db',
									'#8e44ad',
									'#e67e22',
									'#e74c3c',
									'#1abc9c',
									'#2c3e50'
								]}
							/>
						)}
					</div>
				</div>
				{ source && <p className="text-xs">{source}</p> }
			</div>
		</a>
	);
}

export default Article;