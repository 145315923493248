import Article from './Components/Article';
import Articles from './Components/Articles';
import Main from './Components/Main';
import Summary from './Components/Summary';
import Description from './Components/Description';
import Statistics from './Components/Statistics';
import Statistic from './Components/Statistic';
import Donate from './Components/Donate';
import React, { useState, useEffect } from "react";
import Loading from './Components/Loading';
import Metadata from './Components/Metadata';

const Ukraine = () => {
	// Stats
	const [stats, setstats] = useState([]);
	const [statsLoading, setStatsLoading] = useState(false);
	const [statsError, setStatsError] = useState();
  
	const endpoint = 'Casualties_of_the_Russo-Ukrainian_War';
	const url = "https://www.wikitable2json.com/api/" + endpoint + "?cleanRef=true&table=2";
  
	const getStats = async () => {
	  let resp;
	  let stats = [];
	  setStatsLoading(true);
	  try {
		resp = await fetch(url);
		const json = await resp.json();
		stats = json
	  } catch (err) {
		setStatsError(err);
	  } finally {
		setStatsLoading(false);
	  }
	  setstats(stats);
	};

	// News
	const [news, setnews] = useState([]);
	const [newsLoading, setNewsLoading] = useState(false);
	const [newsError, setNewsError] = useState();
	const [connected, setConnected] = useState(false);

	useEffect(() => {
		getStats();
	
		if(!connected) {
			const ws = new WebSocket(process.env.REACT_APP_WEBHOOK_URL + '/ukraine');
			ws.onmessage = function (event) {
				setConnected(true);
				setNewsLoading(true);
				const json = JSON.parse(event.data);
				console.log('🎁 Received ws message');
				try {
					if ((json.event = "data")) {
						setTimeout(function(){
							setnews(json);
							setNewsLoading(false);
						}, 1600);
					}
					} catch (err) {
						setNewsError(err);
						setConnected(false);
					}
			};
		}
	}, []);

	return (
		<>	
			<Metadata
				title="Latest Ukraine 2022 Invasion News & Statistics"
				description="The most up to date breaking news and statistics about the 2022 Russian Invasion of Ukraine."
			/>
			<Summary title="Ukraine War">
				<>
					<Donate title="Click here to make a donation" url="/donate" />
					<Description>
						<p>
							Russia began a full-scale invasion of Ukraine on 24 February 2022, in an escalation of the Russo-Ukrainian War that began in 2014. The invasion is the largest conventional military attack in Europe since World War II.
						</p>
						<p className="mt-4">
							Citing the new sanctions and "aggressive statements", Putin placed Russia's nuclear forces on higher alert on 27 February, raising tensions between the West and Russia and increasing fears of a nuclear war.
						</p>
						<p className="mt-4">
							The invasion has caused Europe's largest refugee crisis since World War II; in just the first week, more than one million people fled Ukraine.
						</p>
					</Description>
					{ stats[0] && (
						<Statistics>
							{stats[0].slice(1).map(item => (
								<li className="mb-6 w-full sm:w-auto xl:w-full max-w-lg min-w-fit sm:mr-4">
									<Statistic
										title={item[0] ?? '?'}
										figure={
											item[1] ?? '?'
											// Remove non-numerical + brackets
											.replace(/[a-z()]/gi, '')
											// Tidy dash ranges
											.replace(/[-–]/g, ' - ')
											+ ' deaths'
										}
										tooltip={ 'Source: ' + item[3] ?? '?' }
									/>
								</li>
							))}
						</Statistics>
					)}
				</>
			</Summary>
			<Main>
				{newsLoading &&
					<Loading />
				}
				<Articles>
					{newsError && <p>Error: {newsError.message}</p>}
					{news && news.map(item => (
						<Article
							key={item.id}
							title={item['title']}
							url={item['url']}
							date={item['published date']}
							source={item['publisher']['title']}
						/>
					))}
				</Articles>
			</Main>
		</>
	);
}
 
export default Ukraine;