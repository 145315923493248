import Statistic from './Statistic';

const Statistics = ( { children } ) => {

	return (
		<div>
			<ul class="mt-8 flex flex-wrap xl:block">
				{ children }
			</ul>
		</div>
	);
}
 
export default Statistics;