import Article from './Components/Article';
import Articles from './Components/Articles';
import Main from './Components/Main';
import Summary from './Components/Summary';
import Description from './Components/Description';
import React, { useState, useEffect } from "react";
import Loading from './Components/Loading';
import Metadata from './Components/Metadata';

const Medical = () => {
	// News
	const [news, setnews] = useState([]);
	const [newsLoading, setNewsLoading] = useState(false);
	const [newsError, setNewsError] = useState();
	
	const [connected, setConnected] = useState(false);
	
	useEffect(() => {
		if(!connected) {
			const ws = new WebSocket(process.env.REACT_APP_WEBHOOK_URL + '/medical');
			ws.onmessage = function (event) {
				setConnected(true);
				setNewsLoading(true);
				const json = JSON.parse(event.data);
				console.log('🎁 Received ws message');
				try {
					if ((json.event = "data")) {
						setTimeout(function(){
							setnews(json);
							setNewsLoading(false);
						}, 1600);
					}
					} catch (err) {
						setNewsError(err);
						setConnected(false);
					}
			};
		}
	}, []);

	return (
		<>
			<Metadata
				title="Medical News"
				description="The most up to date breaking news in the medical industry."
			/>
			<Summary title="Medical">
				<>
					<Description>
						<p>
							The healthcare industry encompasses a wide range of sectors that provide goods and services to treat patients. This includes things such as treating people, preventing illness, and providing rehabilitative medicine and palliative care.
						</p>
					</Description>
				</>
			</Summary>
			<Main>
				{newsLoading && 
					<Loading />
				}
				<Articles>
					{newsError && <p>Error: {newsError.message}</p>}
					{news && news.map(item => (
						<Article
							key={item.id}
							title={item['title']}
							url={item['url']}
							date={item['published date']}
							source={item['publisher']['title']}
						/>
					))}
				</Articles>
			</Main>
		</>
	);
}
 
export default Medical;