import {Helmet} from "react-helmet";

const Metadata = ({
	  title,
	  description,
	  onlySiteName,
}) => {
	return (
		<Helmet>
			<title>{ process.env.REACT_APP_NAME } { onlySiteName === true ? '' : ' || ' + title }</title>
			<meta name="description" content={ description } />
			<link rel="canonical" href={ process.env.REACT_APP_URL + window.location.pathname } />
		</Helmet>
	);
}
 
export default Metadata;