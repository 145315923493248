import Masonry from 'react-masonry-css'

const Articles = ({ children }) => {
	const breakpointColumnsObj = {
		default: 7,
		6800: 6,
		5800: 5,
		3600: 4,
		2480: 3,
		1680: 2,
		1080: 2,
		800: 1
	  };

	return (
		<Masonry breakpointCols={breakpointColumnsObj} className="masonry-grid" columnClassName="masonry-col">
			{ children }
		</Masonry>
	);
}
 
export default Articles;