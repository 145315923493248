import React, { memo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/v2/topojson-maps/world-110m.json";


const Map = ({ setTooltipContent, disableEnter, disableClick, closeModal, graticule }) => {
	var onMouseEnter = (setTooltipContent, geo) => {
		if(disableEnter){
		}
		else {
			const { NAME } = geo.properties;
			setTooltipContent(`${NAME}`);
		}
	};

	var onMouseClick = (setTooltipContent, geo, onClick) => {
		if(disableClick){
		}
		else {
			const { NAME } = geo.properties;
			setTooltipContent(`${NAME}`);
			closeModal(false);
		}
	};

	return (
		<>
		<ComposableMap data-tip="" projectionConfig={{
			scale: 147,
		}}>
		  { graticule && (
          <	Graticule stroke="rgba(255,255,255,0.05)" />
		  )}
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
				  onMouseEnter={() => onMouseEnter(setTooltipContent, geo)}
				  onClick={() => onMouseClick(setTooltipContent, geo)}
                  style={{
                    default: {
                      fill: "rgba(255, 255, 255, 0.0)",
					  stroke: "#898991",
					  outline: "none"
					},
                    hover: {
                      fill: "currentColor",
					  stroke: "#898991",
					  outline: "none"
                    },
                    pressed: {
                      fill: "currentColor",
					  stroke: "#898991",
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
      </ComposableMap>
		</>
	);
}
 
export default memo(Map);