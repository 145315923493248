// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, NavLink, Navigate, Route, Routes, Link, useLocation} from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import Home from './Home';
import Ukraine from './Ukraine';
import Medical from './Medical';
import Covid from './Covid';
import Navigation from './Navigation';
import Donate from './Donate';
import { EmojiProvider, Emoji } from 'react-apple-emojis';
import emojiData from 'react-apple-emojis/lib/data.json';

function App() {
  const animations = {
		initial: { opacity: 0,},
		animate: { opacity: 1,},
		exit: { opacity:0,},
	};

	const location = useLocation();

	const routes = [
		{
			title: 'Home', 
      icon: '',
			nav: false,
			path: '/',
			Component: ( <Home /> )
		},
		{
			title: 'Ukraine',
      icon: (
        <EmojiProvider data={emojiData}>
          <Emoji name="flag-ukraine" />
        </EmojiProvider>
      ),
			nav: true,
			path: '/ukraine',
			Component: ( <Ukraine /> )
		},
    {
			title: 'COVID-19',
      icon: (
        <EmojiProvider data={emojiData}>
          <Emoji name="face-with-medical-mask" />
        </EmojiProvider>
      ),
			nav: true,
			path: '/covid',
			Component: ( <Covid />)
		},
    {
			title: 'Medical',
      icon: (
        <EmojiProvider data={emojiData}>
          <Emoji name="pill" />
        </EmojiProvider>
      ),
			nav: true,
			path: '/medical',
			Component: ( <Medical /> )
		},
    {
			title: 'Donate',
      icon: (
        <EmojiProvider data={emojiData}>
          <Emoji name="reminder-ribbon" />
        </EmojiProvider>
      ),
			nav: true,
			path: '/donate',
			Component: ( <Donate /> )
		},
	];

  return (
    <div className="App h-full min-h-screen text-black dark:text-white bg-white dark:bg-slate-900">
      <div className="w-4/5 mx-auto">
        <Navigation routes={routes} />

        <main class="h-full">
          <AnimatePresence exitBeforeEnter>
            <Routes key={location.pathname} location={location}>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={ 
                  <motion.div
                    variants={animations}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.5 }}
                  >
                    <div className="xl:flex justify-between">
                      { route.Component }
                    </div>
                    <div className="text-center font-bold opacity-80 py-12">
                      STATSORB INC. 2022
                    </div>
                  </motion.div>
                } />
              ))}
              </Routes>
            </AnimatePresence>
        </main>
      </div>
    </div>
  );
}

export default App;
