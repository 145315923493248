import ReactTooltip from 'react-tooltip';

const Statistic = ({title, figure, tooltip}) => {
	return (
		<div className="border-black/30 dark:border-white/30 text-black dark:text-white border-[1px] flex items-center justify-between p-6 rounded-2xl">
			<div className="" { ...(tooltip ? { "data-tip": tooltip } : '') } >
				{title}
				{tooltip && (
					<ReactTooltip />
				)}
			</div>
			<div className="ml-8 text-right">
				{figure}
			</div>
		</div>
	);
}
 
export default Statistic;