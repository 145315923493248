const Summary = ({ children, title, noLive }) => {
	return (
		<div className="w-full xl:w-1/3">
			<div className="flex items-center">
				<h1 className="font-bold text-3xl md:text-6xl">{title}</h1>
				{!noLive && (
					<span class="flex h-4 w-4 relative ml-8">
						<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"></span>
						<span class="relative inline-flex rounded-full h-4 w-4 bg-red-600"></span>
					</span>
				)}
			</div>
			{ children }
		</div>
	);
}
 
export default Summary;