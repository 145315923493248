import Article from './Components/Article';
import Articles from './Components/Articles';
import Main from './Components/Main';
import Summary from './Components/Summary';
import Description from './Components/Description';
import React, { useState, useEffect } from "react";
import Loading from './Components/Loading';
import Metadata from './Components/Metadata';


const Donate = () => {
	// News
	const charities = [
		{
			name: 'Epilepsy Foundation',
			url: 'https://www.epilepsy.com',
			description: 'The mission of the Epilepsy Foundation is to lead the fight to overcome the challenges of living with epilepsy and to accelerate therapies to stop seizures, find cures, and save lives.',
			logo: 'epilepsy-foundation.jpg',
		},
		{
			name: 'St. Jude Children’s Research Hospital',
			url: 'https://www.stjude.org',
			description: "A leading children's hospital, St. Jude treats the toughest childhood cancers and pediatric diseases",
			logo: 'st-jude.jpg',
		},
		{
			name: 'The Bill & Melinda Gates Foundation',
			url: 'https://www.gatesfoundation.org',
			description: 'The main focus of the Foundation’s Global Health Program is fighting and preventing diseases such as HIV/AIDS, malaria and tuberculosis.',
			logo: 'gates.jpg',
		},
		{
			name: 'The Wellcome Trust',
			url: 'https://wellcome.org',
			description: 'The Wellcome Trust is the largest medical research charity in the world, “dedicated to achieving extraordinary improvements in human and animal health.”',
			logo: 'wellcome.png',
		},
		{
			name: 'AmeriCares',
			url: 'https://www.americares.org',
			description: 'An international charity that provides an emergency medicines, medical supplies and treatments to disaster areas across the world.',
			logo: 'americares.jpg',
		},
		{
			name: 'UNICEF',
			url: 'https://www.unicef.org.uk',
			description: 'The United Nations Children Fund was created in 1946 to provide food and shelter to children affected by WW2. Today it is one of the leading charities promoting children’s rights.',
			logo: 'unicef.jpg',
		},
		{
			name: 'The Red Cross',
			url: 'https://www.redcross.org.uk',
			description: 'The British Red Cross helps people in crisis, whoever and wherever they are.',
			logo: 'red-cross.png',
		},
		{
			name: 'Feed The Children',
			url: 'https://www.feedthechildren.org',
			description: 'Feed The Children gives food, medicine and clothing to children and their families affected by poverty, famine, disasters and conflict.',
			logo: 'feed-the-children.jpg',
		},
		{
			name: 'Médicines San Frontières',
			url: 'https://www.msf.org',
			description: 'MSF provides emergency medical relief and care to over 60 developing countries across the world. It also works in partnership with health authorities to develop and strengthen healthcare training and educational programmes.',
			logo: 'msf.png',
		},
		{
			name: 'Cancer Research UK',
			url: 'https://www.cancerresearchuk.org',
			description: 'Cancer Research UK, the world’s biggest independent cancer charity.',
			logo: 'cancer-research.png',
		},
		{
			name: 'Water Aid',
			url: 'https://www.wateraid.org',
			description: 'Contaminated drinking water leads to approximately two million deaths each year from diseases like cholera and typhoid. Water Aid’s sole mission is to give everyone in the world access to clean drinking water and it works in 26 countries in Asia, Africa and the Pacific region to achieve this.',
			logo: 'water-aid.jpg',
		},
		{
			name: 'Avert',
			url: 'https://avert.org',
			description: 'Avert is a charity dedicated to preventing the global spread of HIV/AIDS through treatment, care and education.',
			logo: 'avert.png',
		},
	]


	return (
		<>
			<Metadata
				title="Donate"
				description="Make a donation to the causes that need it most"
			/>
			<div className="w-full">
				<div className="flex items-center">
					<h1 className="font-bold text-3xl md:text-6xl">Donate</h1>
				</div>
				<div className="mt-8 leading-loose">
					<p>
						Donate to one of the charities below to help the causes that need the most help.
					</p>
				</div>
				<div className="mt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
					{charities.map((charity, i) => (
						<a href={charity.url} target="_blank" rel="noopener noreferrer">
							<div className="h-full flex justify-between border-black/30 dark:border-white/30 bg-black/10 dark:bg-white/10 hover:dark:bg-white/20 hover:bg-black/20 transition-all duration-500 text-black dark:text-white border-[1px] px-6 py-4 rounded-2xl">
								<div className="w-32 h-full object-cover mr-4">
									<img className="w-full" src={'/assets/charities/' + charity.logo} alt={charity.name} />
								</div>
								<div className="flex w-full justify-between mb-3">
									<div class="w-fit">
										<p>{charity.description}</p>
									</div>
								</div>
							</div>
						</a>
					))}
				</div>
			</div>
		</>
	);
}
 
export default Donate;