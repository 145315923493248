import { NavLink } from 'react-router-dom';

const Navigation = ({ routes }) => {
	const navLinkClasses = 'opacity-50 hover:opacity-100 transition-all flex items-center my-4';
	const liClasses = 'flex items-center justify-center lg:justify-start w-full md:w-auto md:mr-16 xl:mr-24';

	return (
		<nav class="py-12 md:py-20">
          <ul class="flex flex-wrap lg:flex-nowrap justify-center md:justify-start">
            {routes.map((route, index) => {
              if(route.title === 'Home') {
                return (
					<li class={liClasses + ' mr-auto w-full mb-8 md:mb-0'}>
						<NavLink
						to={route.path}
						className={navLinkClasses}
						activeClassName="active"
						exact={true}
						>
							<svg class="h-24" id="Group_61" data-name="Group 61" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.682 50.772">
								<g id="Ellipse_3" data-name="Ellipse 3" transform="translate(6.377 3.543)" fill="none" stroke="currentColor" stroke-width="5">
									<circle cx="19.839" cy="19.839" r="19.839" stroke="none"/>
									<circle cx="19.839" cy="19.839" r="17.339" fill="none"/>
								</g>
								<path id="Path_5" data-name="Path 5" d="M39.1,0S30.344,21.038,19.548,21.038,0,0,0,0Z" transform="matrix(0.777, -0.629, 0.629, 0.777, 10.059, 34.422)" fill="currentColor"/>
								<circle id="Ellipse_7" data-name="Ellipse 7" cx="1.771" cy="1.771" r="1.771" transform="translate(44.638 7.794)" fill="currentColor"/>
								<circle id="Ellipse_8" data-name="Ellipse 8" cx="2.126" cy="2.126" r="2.126" transform="translate(14.171 43.929)" fill="currentColor"/>
								<circle id="Ellipse_9" data-name="Ellipse 9" cx="4.251" cy="4.251" r="4.251" fill="currentColor"/>
								<path id="Path_4" data-name="Path 4" d="M372.519,5022.8l26.761-22.18" transform="translate(-361.815 -4989.552)" fill="none" stroke="currentColor" stroke-width="5"/>
							</svg>
						</NavLink>
					</li>
                )
              }
              else {
                return (
                  <li class={liClasses}>
                    <NavLink
                      to={route.path}
                      className={navLinkClasses}
                      activeClassName="active"
                      exact={true}
                    >
                      <span class="w-8 md:w-16">{route.icon}</span>
                      <span class="ml-4 text-lg">{route.title}</span>
                    </NavLink>
                  </li>
                )
              }
            })}
          </ul>
        </nav>
	);
}
 
export default Navigation;